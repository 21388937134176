:root {
  --brand-color: #ffff9e;
  --brand-color-darker: #de8;
  --brand-color-darker-darker: #cd7;
  --border-color: var(--brand-color-darker);
  --border-color-darker: var(--brand-color-darker-darker);
  --black: #000;
  --white: #fff;
  --red: red;
  --background-color: var(--white);
  --text-color: #222;
  --link-color: #54c;
  --active-link-color: var(--black);
  --summary-marker-color: var(--border-color);
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
}

.dark-mode {
  --brand-color: #444;
  --brand-color-darker: #555;
  --brand-color-darker-darker: #666;
  --border-color: #444;
  --border-color-darker: #555;
  --background-color: #222;
  --text-color: #fff;
  --link-color: #e4bfff;
  --active-link-color: #fff;
  --summary-marker-color: #eee;
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

body {
  background: var(--background-color);
  color: var(--text-color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Aptos, Segoe UI Variable, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.skip {
  text-align: center;
  height: 0;
  display: block;
  overflow: hidden;
}

.skip:focus {
  height: auto;
}

code, pre {
  font-family: ui-monospace, Monaco, Consolas, monospace;
  font-size: 14px;
}

header, main, footer {
  display: block;
}

main {
  margin: 0 auto;
  padding: 0 16px;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration-skip-ink: auto;
  text-decoration: underline;
}

a:active {
  background-color: var(--brand-color);
  color: var(--active-link-color);
}

abbr {
  cursor: help;
  font-weight: bold;
  text-decoration: none;
}

kbd {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: .5em;
  padding: .25em;
}

label {
  cursor: pointer;
}

details {
  margin: 1em 0;
}

summary {
  cursor: pointer;
  font-weight: bold;
}

summary::marker {
  color: var(--border-color-darker);
}

.cf-turnstile {
  margin-top: -1em;
}

.errorlist:not(.nonfield) {
  color: red;
  float: right;
  margin: 0;
  font-size: 14px;
}

.errorlist.nonfield {
  float: none;
  border: 1px solid red;
  padding: 1em;
}

#id_features {
  margin-top: -.5em;
}

#id_reg, #id_previous_reg {
  text-transform: uppercase;
}

body .select2-container .select2-selection {
  background: var(--background-color);
  border-color: var(--border-color);
  border-radius: 8px;
  height: 34px;
}

body .select2-container .select2-selection .select2-selection__rendered {
  color: var(--text-color);
  line-height: 32px;
}

body .select2-container .select2-selection .select2-selection__arrow {
  height: 32px;
}

body .select2-container .select2-selection .livery {
  margin-top: -2px;
}

body .select2-dropdown {
  background: var(--background-color);
}

.site-header {
  background: var(--brand-color);
  position: relative;
  overflow: hidden;
}

.site-header a {
  color: var(--header-text-color);
  padding: 16px;
  line-height: 1;
  display: inline-block;
}

.site-header a:hover {
  background-color: var(--brand-color-darker);
  text-decoration: none;
}

.site-header .site-name {
  font-size: 24px;
}

.site-header div {
  margin: 0 auto;
}

.site-header div .site-name {
  position: absolute;
}

.site-header ul {
  float: right;
  margin: 0;
}

.site-header ul li {
  display: inline;
}

.site-header ul a {
  padding: 1em 1em 1em .5em;
  line-height: 1.5em;
}

.site-header .search {
  float: right;
}

.site-header .search label {
  padding: 16px;
  line-height: 1.5;
  display: block;
}

.site-header .search label input {
  z-index: 1;
  border-radius: 0;
  margin: 0;
  position: absolute;
  top: -48px;
}

.site-header .search label input:focus {
  border-width: 12px 16px;
  width: 100%;
  top: 0;
  left: 0;
}

.site-header .search label + input {
  display: none;
}

.site-header ul + .search label {
  padding-right: 8px;
}

.search p {
  display: flex;
}

.search input[type="search"] {
  flex-grow: 1;
  margin-right: 8px;
}

h1, h2 {
  margin: .5em 0;
  line-height: 1.2em;
}

h1 {
  font-size: 28px;
}

@font-face {
  font-family: Charles Wright;
  src: url("../CharlesWright-Bold.0ab05dc7.woff2") format("woff2"), url("../CharlesWright-Bold.f184838c.woff") format("woff");
}

.reg {
  text-align: center;
  color: var(--text-color);
  font-family: Charles Wright;
  font-weight: normal;
}

h1 .reg {
  border: 1px solid var(--border-color);
  border-radius: 2px;
  padding: 8px 6px 2px;
  font-size: 24px;
}

h2 {
  margin-top: 1em;
  font-size: 20px;
}

section header {
  align-items: baseline;
  gap: 1em;
  display: flex;
}

section header h2 {
  margin: .5em 0 0;
}

section header a {
  line-height: 1;
}

h3 {
  font-size: 1em;
}

.bulleted li {
  margin-left: 1em;
  list-style: outside;
}

.button, input[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
  background: var(--brand-color);
  border: 0;
  border-bottom: 1px solid var(--brand-color-darker);
  color: inherit;
  font: inherit;
  border-radius: 12px;
  margin-bottom: 1px;
  padding: 4px 8px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.button:hover, input[type="submit"]:hover {
  border-bottom-width: 2px;
  margin-bottom: 0;
  text-decoration: none;
  top: 0;
}

.button:focus, .button:active, input[type="submit"]:focus, input[type="submit"]:active {
  background-color: var(--brand-color-darker);
  border-bottom: 0;
  margin-bottom: 2px;
  top: 1px;
}

input[type="text"], input[type="url"], input[type="email"], input[type="password"], input[type="number"], textarea {
  width: 100%;
  display: block;
}

input[type="text"], input[type="url"], input[type="number"], input[type="email"], input[type="password"], input[type="search"], input[type="date"], input[type="time"], textarea {
  -webkit-appearance: none;
  appearance: none;
  background: var(--background-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  border-radius: .5em;
  outline: 0;
  padding: .25em .5em;
}

input[type="text"]:focus, input[type="url"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="date"]:focus, input[type="time"]:focus, textarea:focus {
  border-color: var(--border-color-darker);
}

.errorlist:not(.nonfield) + p input[type="text"], .errorlist:not(.nonfield) + p input[type="url"], .errorlist:not(.nonfield) + p input[type="number"], .errorlist:not(.nonfield) + p input[type="email"], .errorlist:not(.nonfield) + p input[type="password"], .errorlist:not(.nonfield) + p input[type="search"], .errorlist:not(.nonfield) + p input[type="date"], .errorlist:not(.nonfield) + p input[type="time"], .errorlist:not(.nonfield) + p textarea {
  border-color: var(--red);
}

::-webkit-datetime-edit {
  padding: 0;
}

::-webkit-calendar-picker-indicator {
  margin-left: 0;
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  color: inherit;
  font: inherit;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 8' width='16' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,0 4,8 8,0'/%3E%3C/svg%3E");
  background-position: 100%;
  background-repeat: no-repeat;
  border-radius: .5em;
  max-width: 100%;
  padding: .25em 1.5em .25em .25em;
}

select::-ms-expand {
  display: none;
}

.dark-mode select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 8' width='16' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon fill='%23eee' points='0,0 4,8 8,0'/%3E%3C/svg%3E");
}

form p select {
  box-sizing: border-box;
  width: 100%;
  display: block;
}

.helptext {
  font-size: 14px;
  display: block;
}

.loading, .htmx-request {
  opacity: .5;
}

.loading, .loading a, .htmx-request, .htmx-request a {
  cursor: progress;
}

.prediction {
  opacity: .6;
}

.groupings {
  flex-wrap: wrap;
  gap: 1em 2em;
  margin-top: 1em;
  display: flex;
}

.grouping {
  flex-grow: 1;
  max-width: 100%;
}

.grouping h2 {
  margin-top: 0;
}

.timetable-date {
  flex-wrap: wrap;
  gap: 1em;
  margin: 1em 0;
  display: flex;
}

.timetable-date select {
  padding: .5em 1.5em .5em .5em;
}

.timetable-date p {
  margin: 0;
}

.timetable-date > div {
  flex-wrap: wrap;
  align-items: center;
  gap: .5em 1em;
  display: flex;
}

.timetable-date label {
  white-space: nowrap;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

ul {
  list-style: none;
}

ul, ol {
  margin: 1em 0;
  padding: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 0 1em;
}

ol li {
  margin-bottom: 1em;
}

ol ::marker {
  font-variant-numeric: normal;
}

.rules > li, .history > li {
  margin-left: 1.75em;
  padding-left: .25em;
  list-style: decimal;
}

.history ul {
  display: flex;
}

.history ul li {
  margin: 0;
}

.history ul li + li {
  margin-left: 1.5em;
}

.history ul strong {
  display: block;
}

main li {
  break-inside: avoid;
}

main li > a {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: .125em 0;
  display: block;
  overflow: hidden;
}

.has-smalls li a, .has-smalls li a:hover .name {
  text-decoration: none;
}

.has-smalls li a:hover span {
  text-decoration: underline;
}

.has-smalls span {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.has-smalls small {
  color: var(--text-color);
  float: left;
  margin-right: .5em;
  font-size: .75em;
  display: block;
}

.has-smalls small:last-child {
  float: none;
  text-overflow: ellipsis;
  margin: 0;
  padding-bottom: .33em;
  overflow: hidden;
}

.bulleted a, main p a, table a {
  padding: 0;
  display: inline;
}

.bulleted a {
  white-space: normal;
}

.breadcrumb {
  border-bottom: 1px solid var(--brand-color-darker);
  white-space: nowrap;
  margin: 0 -1em;
  padding: .5em 0 0 .5em;
  overflow: auto;
}

.breadcrumb li {
  margin: -.5em 0 0;
  display: inline-block;
}

.breadcrumb li:after {
  color: var(--border-color-darker);
  content: "›";
  vertical-align: middle;
  padding: 0;
}

.breadcrumb li:last-child:after {
  width: 0;
  padding-right: 0;
}

.breadcrumb a {
  vertical-align: middle;
  padding: .75em .5em;
  display: inline-block;
}

.services {
  column-width: 24em;
  column-gap: 1em;
}

.services a {
  white-space: normal;
  align-items: center;
  padding: .5em 0;
  display: flex;
}

.services a small {
  color: var(--text-color);
  white-space: nowrap;
  margin-left: 1em;
}

.services a:hover {
  text-decoration: none;
}

.services a:hover .description {
  text-decoration: underline;
}

.services a:focus, .services a:active {
  color: var(--active-link-color);
}

.name {
  border: 1px solid var(--border-color);
  color: var(--text-color);
  float: left;
  text-align: center;
  white-space: nowrap;
  margin-right: 16px;
  padding: 6px;
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
}

.name.is-short {
  height: 36px;
  padding: 6px 8px;
  font-size: 30px;
  line-height: 36px;
}

.name.is-short + .name {
  white-space: normal;
  align-content: center;
  height: 36px;
}

.name:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.name:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.name + .name {
  border-left: 0;
  margin-left: -16px;
}

.service-header {
  flex-wrap: wrap;
  row-gap: 8px;
  display: flex;
}

.service-header .is-short {
  font-weight: 500;
}

.service-header .description {
  flex-grow: 1;
  flex-basis: 70%;
  align-self: center;
}

.has-smalls .name {
  margin-right: .75em;
}

.has-smalls .name + .name {
  margin-left: -.75em;
}

#hugemap {
  position: absolute;
  inset: 56px 0 0;
}

#hugemap .map-link {
  background: var(--brand-color);
  color: var(--header-text-color);
  padding: 1em 1em 1em .5em;
  text-decoration: none;
  position: absolute;
  top: -3.5em;
  right: 0;
}

#hugemap .map-link:hover {
  background-color: var(--brand-color-darker);
}

.big-map, .service-map {
  position: absolute;
  inset: 0;
}

.service-map {
  background: var(--background-color);
  box-shadow: var(--background-color) 0 -100px;
  z-index: 1;
  position: fixed;
}

#map .service-map {
  display: none;
}

#map:target .service-map {
  display: block;
}

.ad-wrapper + #map .service-map {
  margin-top: 90px;
}

.sorry {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.livery {
  vertical-align: middle;
  border: 1px solid var(--active-link-color);
  width: 2.25em;
  height: 1.5em;
  display: inline-block;
}

table {
  border-collapse: collapse;
}

table .is-highlighted {
  background: var(--brand-color);
}

.compact {
  font-size: .875em;
}

.compact td {
  vertical-align: top;
}

.fleet .livery-cell {
  position: relative;
}

.fleet .livery-cell .livery {
  position: absolute;
  top: .2em;
  left: .5em;
}

.fleet td {
  overflow: hidden;
}

.fleet .trivia {
  display: none;
}

.fleet .last-seen {
  max-width: 5em;
}

.fleet tr:target {
  background: var(--brand-color);
}

.revisions-list li + li {
  border-top: 1px solid var(--border-color);
}

.sidebar form {
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 2em;
  display: flex;
}

.sidebar form p {
  flex-grow: 1;
  flex-basis: 7em;
  margin: 0;
}

.sidebar form .errorlist {
  display: none;
}

.sidebar form .errorlist + p select, .sidebar form .errorlist + p input {
  border-color: red;
}

.sidebar form p:last-child {
  flex-grow: 0;
  flex-basis: 0;
  margin-top: 1.5em;
}

.wrap, .wrap a {
  white-space: normal;
  overflow-wrap: anywhere;
}

td {
  border-top: 1px solid var(--border-color);
}

th a, .link a, .fleet a {
  margin: -.25em -.5em;
  padding: .25em .5em;
  display: block;
}

.stop-name[rowspan="2"] a {
  padding: 1.25em 0;
}

th, td {
  border-left: 1px solid var(--border-color);
  text-align: left;
  padding: .25em .5em;
}

th:first-child, td:first-child {
  border-left: 0;
  padding-left: 0;
}

th:first-child a, td:first-child a {
  margin-left: 0;
  padding-left: 0;
}

th:last-child, td:last-child {
  padding-right: 0;
}

th:last-child a, td:last-child a {
  margin-right: 0;
  padding-right: 0;
}

thead th {
  vertical-align: bottom;
  padding-top: 0;
}

.table-wrapper {
  margin: 0 -1em;
  overflow-x: auto;
}

.table-wrapper table {
  border-collapse: separate;
  border-spacing: 0;
  white-space: nowrap;
  padding: 0 1rem;
}

.nowrap {
  white-space: nowrap;
}

#timetable {
  margin-top: 1em;
}

.timetable-wrapper-wrapper {
  margin: 1em 0;
  font-size: .875em;
}

.timetable-wrapper-wrapper input {
  margin: 0;
}

.timetable-wrapper-wrapper label {
  margin-right: 1em;
  padding-left: .5em;
}

.timetable-wrapper {
  margin: 1em -1.14em 0;
  position: relative;
  overflow-x: auto;
}

.timetable-wrapper--wide {
  margin-left: 0;
}

input:not(:checked) + label + .timetable-wrapper .minor, input:not(:checked) + label + .route .minor {
  display: none;
}

input:not(:checked) + label + .timetable-wrapper .od, input:not(:checked) + label + .timetable-wrapper .referrer, input:not(:checked) + label + .timetable-wrapper .referrer + .dep, input:not(:checked) + label + .route .od, input:not(:checked) + label + .route .referrer, input:not(:checked) + label + .route .referrer + .dep {
  display: table-row;
}

.timetable {
  border-collapse: separate;
  border-spacing: 0;
  padding: 0 1.14em;
}

.timetable-wrapper--wide .timetable {
  padding-left: 0;
}

.timetable .then-every {
  width: 0;
}

.timetable tbody td {
  font-variant-numeric: tabular-nums;
  border-left: 0;
}

.timetable td, .timetable th {
  height: 1.5em;
}

.timetable tbody tr:first-child td, .timetable tbody tr:first-child th {
  border-top: 0;
}

.timetable .referrer td, .timetable .referrer th, .timetable .referrer + .dep td, .timetable .referrer + .dep th {
  background: var(--brand-color);
}

.timetable .referrer .then-every, .timetable .referrer + .dep .then-every {
  background: none;
}

.timetable .referrer a, .timetable .referrer + .dep a {
  color: var(--active-link-color);
}

.timetable thead th {
  border-top: 0;
  border-bottom: 1px solid var(--border-color);
  text-align: right;
  padding-right: .5em;
}

.timetable thead td {
  border: 1px solid var(--border-color);
  text-align: center;
  border-width: 0 1px 1px 0;
}

.timetable thead td:last-child {
  border-right: 0;
}

.timetable tfoot td {
  border: 1px solid var(--border-color);
  vertical-align: top;
  border-width: 1px 1px 0 0;
}

.timetable tfoot td:last-child {
  border-right: 0;
}

.timetable .dep th, .timetable .dep td, .timetable .minor + .minor th, .timetable .minor + .minor td {
  border-top: 0;
}

.timetable .dep td:first-child {
  padding-left: .5em;
}

.timetable .minor .stop-name {
  padding-left: 1em;
}

.timetable .minor .stop-name a {
  margin-left: -1em;
  padding-left: 1em;
}

.timetable th {
  background: var(--background-color);
  border-right: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
  white-space: nowrap;
  font-weight: normal;
}

.timetable-wrapper--wide .stop-name, .timetable-wrapper--wide th {
  max-width: calc(100vw - 14em);
  position: sticky;
  left: 0;
  overflow: hidden;
}

.route a {
  margin-left: 1em;
}

.route li:before {
  border: 3px solid var(--border-color);
  content: " ";
  border-width: 3px 0 0 3px;
  width: 6px;
  height: 1.75em;
  margin-top: .85em;
  display: block;
  position: absolute;
}

.route li:last-child:before {
  height: 0;
}

.route .minor a {
  margin-left: 2em;
  font-size: .875em;
}

.route .minor:before {
  border-top-width: 1px;
  width: 1em;
}

.journey-map {
  position: absolute;
  inset: 0;
}

.has-sidebar {
  bottom: 45%;
}

.no-stops {
  bottom: 248px;
}

.map-sidebar {
  padding: 1em 1em 100px;
  position: absolute;
  inset: 55% 0 0;
  overflow: auto;
}

.no-stops + .map-sidebar {
  height: 132px;
  top: auto;
}

.map-sidebar .navigation {
  overflow: hidden;
}

.trip-timetable .breadcrumb {
  border: 0;
  margin: -1em -1em 1em;
  padding-top: .65em;
  padding-left: .5em;
}

.trip-timetable .breadcrumb a {
  padding: .25em .5em;
}

.trip-timetable label + table {
  margin-top: -2em;
}

.trip-timetable table {
  table-layout: fixed;
  width: 100%;
}

.trip-timetable tbody {
  white-space: nowrap;
}

.trip-timetable th, .trip-timetable td {
  width: 48px;
  padding: 4px 8px;
}

.trip-timetable th {
  border: 0;
  border-bottom: 1px solid var(--border-color);
  font-size: 12px;
}

.trip-timetable .stop-name {
  width: auto;
}

.trip-timetable td {
  border-left: 1px solid var(--border-color);
}

.trip-timetable td:last-child {
  padding-right: 0;
}

.trip-timetable .stop-name {
  border-left: 0;
  padding-left: 0;
}

.trip-timetable .stop-name a {
  margin: -4px -8px -4px 0;
  padding: 4px 8px 4px 0;
  display: block;
  overflow: hidden;
}

.trip-timetable .minor {
  font-size: 14px;
}

.trip-timetable .minor + .minor td {
  border-top: 0;
}

.trip-timetable .previous, .trip-timetable .next {
  margin: 0;
}

.trip-timetable .is-highlighted a {
  color: var(--active-link-color);
}

.credit {
  clear: both;
  overflow-wrap: anywhere;
  font-size: .875em;
}

.aside {
  clear: right;
  margin-top: 1em;
}

.aside:first-child {
  margin-top: 0;
}

.aside table {
  margin: 0 -.5em;
}

.aside th {
  vertical-align: bottom;
  border: 0;
  padding: 0 0 0 .5rem;
  font-size: .75em;
}

.aside td {
  vertical-align: top;
  border: 0;
  padding: .125em .5em;
}

.aside td:nth-child(2) {
  width: 100%;
}

.aside td:last-child {
  white-space: nowrap;
}

.aside td a {
  margin: -.125em -.5em;
  padding: .125em .5em;
  display: block;
}

.aside h2 {
  margin: .25em 0 .5em;
}

.aside h3 {
  margin: 1em 0 -1em;
}

.aside p {
  margin: .5em 0;
}

.aside ul {
  margin: 0;
}

.aside .credit {
  text-align: right;
  margin: .3em 0 0;
  font-size: .75em;
}

.aside form {
  gap: .5em;
  margin-bottom: .5em;
  display: flex;
}

.aside input {
  flex-grow: 1;
}

.aside .vehicle {
  font-size: .75em;
}

.maplibregl-popup-content time, .tabular, #fare-table td, .aside td, .trip-timetable td {
  font-variant-numeric: tabular-nums;
}

.message {
  border: 1px solid var(--border-color);
  padding: 1em;
}

.message p {
  margin-top: 0;
}

.situations {
  border: 1px solid var(--border-color);
  border-bottom: 0;
  margin: 1em 0;
}

.situations .situation {
  border-bottom: 1px solid var(--border-color);
  margin: 0;
  padding: 1em;
}

.situations .situation p:first-child {
  margin-top: 0;
}

.situations .situation p:last-child {
  margin-bottom: 0;
}

.situations summary {
  margin: -1em;
  padding: 1em;
}

.situations .dates {
  margin-left: 1em;
  font-weight: normal;
  display: inline-block;
}

.ticket {
  border: 1px solid var(--border-color);
  color: inherit;
  margin-bottom: -1px;
  padding: 1em;
  display: block;
  position: relative;
}

.ticket:hover {
  border-color: var(--brand-color-darker-darker);
  z-index: 1;
  text-decoration: none;
}

.ticket h2, .ticket h3 {
  margin: 0;
  display: inline-block;
}

.ticket p {
  margin-bottom: 0;
}

.ticket .price {
  float: right;
  margin: 0;
}

.contact-details {
  overflow-wrap: anywhere;
  flex-wrap: wrap;
  gap: 1em;
  display: flex;
}

.contact-details dd {
  margin: 0;
}

.contact-details .livery {
  margin: .3em .5em 0 0;
}

.previous {
  float: left;
}

.next {
  float: right;
}

nav {
  margin: 1em auto;
  overflow: hidden;
}

nav ul, nav p {
  margin: 0;
}

.ad-wrapper {
  text-align: center;
  margin: 1em -1em 10px;
}

body .ad-wrapper {
  min-height: 120px;
}

.ad-wrapper iframe {
  vertical-align: bottom;
}

@media (height >= 480px) {
  .ad-wrapper {
    pointer-events: none;
    z-index: 9999;
    position: sticky;
    top: 0;
  }

  .ad-wrapper iframe {
    background: var(--background-color);
    pointer-events: auto;
  }
}

#content {
  margin-bottom: 1.5em;
}

.no-scroll {
  overflow: hidden;
}

body.has-overlay {
  position: relative;
  overflow: hidden;
}

body.has-overlay #AFM_inContentTop_ad {
  z-index: 9999;
  pointer-events: auto;
  background: var(--background-color);
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

body.has-overlay #timetable {
  content-visibility: hidden;
}

.horizontal li, footer li, .horizontal li a, footer li a {
  display: inline;
}

.horizontal li:before, footer li:before {
  color: var(--border-color-darker);
  content: "  · ";
  font-weight: bold;
}

.horizontal li:first-child:before, footer li:first-child:before {
  display: none;
}

.tabs {
  border-bottom: 1px solid var(--brand-color-darker);
  margin: 1em -1em;
  display: flex;
  overflow: visible;
}

.tabs > li {
  box-shadow: inset 0 -4px var(--brand-color-darker);
  float: left;
  margin: 0 1em;
  padding-top: .25em;
  padding-bottom: .75em;
}

.tabs > li > a {
  box-shadow: inset 0 -4px var(--background-color);
  margin: -.25em -1em -.75em;
  padding: .25em 1em .75em;
}

.regions li {
  padding: .125em 0;
}

.regions a {
  margin: -.125em 0;
}

.regions ul {
  margin: .25em 0 .25em 1em;
}

footer {
  clear: both;
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 100px;
}

footer ul {
  border-top: 1px solid var(--brand-color-darker);
  clear: both;
  white-space: nowrap;
  margin: 0;
  padding: 1em;
  font-size: .75em;
  overflow: hidden;
}

footer ul a {
  padding: 1.2em .25em;
}

.no-ads .service-map {
  margin-top: 0;
}

.no-ads footer {
  margin-bottom: 0;
}

.no-ads .ad-wrapper {
  display: none;
}

@media (width >= 30em) {
  .site-header ul a {
    padding-left: 1em;
  }

  .site-header .search {
    padding-right: 1em;
    position: relative;
    top: .7em;
  }

  .site-header .search label {
    float: left;
    width: 8em;
    padding: 0;
    position: relative;
  }

  .site-header .search input[type="search"] {
    border: 1px solid var(--brand-color-darker);
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em;
    width: 100%;
    top: 0;
    left: 0;
  }

  .site-header .search input[type="search"]:focus {
    border-width: 1px;
  }

  .site-header .search input[type="submit"] {
    background: var(--brand-color-darker);
    border: 1px solid var(--brand-color-darker);
    float: right;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
    display: block;
    top: 0;
  }

  .site-header ul + .search {
    padding-right: .5em;
  }

  #hugemap .map-link {
    padding-left: 1em;
  }

  .long {
    column-count: 2;
  }

  .long.wide {
    column-count: 1;
  }
}

@media (width >= 48em) {
  header .search label {
    width: 10em;
  }

  .narrow header div {
    width: 47.5em;
  }

  .narrow main {
    width: 45.5em;
  }

  .panes {
    justify-content: space-between;
    margin: -1em 0;
    display: flex;
  }

  .contact-details {
    margin-top: 1em;
  }

  .contact-details p {
    break-inside: avoid;
    margin-top: 0;
  }

  .asides {
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 2em;
    display: flex;
  }

  .aside {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 300px;
    margin-top: 0;
  }

  .fleet .trivia {
    display: table-cell;
  }

  .has-sidebar, .map-sidebar {
    height: auto;
    margin: 0;
    position: absolute;
  }

  .has-sidebar {
    bottom: 0;
    left: 40%;
    right: 0;
  }

  .map-sidebar {
    box-sizing: border-box;
    width: 40%;
    top: 0;
    overflow: auto;
  }

  .journey-map + .map-sidebar {
    padding-top: 3em;
    top: 0;
  }
}

@media (width >= 62.5em) {
  .site-header div {
    padding: 0 1em;
  }

  main {
    padding: 0 2em;
  }

  .table-wrapper {
    margin: 0 -2em;
  }

  .table-wrapper table {
    padding: 0 2rem;
  }

  .breadcrumb {
    margin: 0 -2em;
    padding-left: 1.5em;
  }

  .ad-wrapper {
    margin: 1em -2em 10px;
  }

  .wide .site-header div {
    width: 62.5em;
  }

  .wide main {
    max-width: 60.5em;
  }

  .timetable-wrapper {
    margin-right: -2.28em;
  }

  .timetable {
    padding-right: 2.28em;
  }

  .long {
    column-count: 3;
  }

  .long.wide {
    column-count: 2;
  }

  .fleet .livery-cell {
    max-width: none;
  }

  .has-sidebar {
    left: 30%;
  }

  .map-sidebar {
    width: 30%;
  }
}

@media print {
  header, .breadcrumb, .tabs, input, label, .ad-wrapper, .qc-cmp2-container, footer {
    display: none;
  }

  body #stickyAdContainer {
    display: none !important;
  }

  body, a {
    color: #000;
  }

  main {
    width: auto;
    margin: 0;
    padding: 0;
  }

  h1 {
    margin-top: 0;
    font-size: 1.75em;
  }

  .timetable-date select {
    border: 0;
    height: auto;
    padding: 0;
  }

  .timetable-date:after {
    display: none;
  }

  .timetable-wrapper {
    margin-top: 0;
  }

  .table-wrapper, .timetable-wrapper {
    overflow: visible;
  }

  .timetable thead td, .timetable td, .timetable th {
    border-color: #eee;
  }
}

@media (width >= 970px) {
  .narrow .ad-wrapper {
    margin: 1em -121px 10px;
  }
}

@media (width >= 1200px) {
  .narrow .ad-wrapper {
    margin: 1em -236px 10px;
  }

  .wide .ad-wrapper {
    margin: 1em -116px 10px;
  }
}

.dark-mode #closeBox circle {
  fill: #fff !important;
}

.dark-mode #closeBox path {
  fill: #000 !important;
}

.dark-mode #AFM_stickyFooter_ad.drawBorder {
  border-color: #000;
}

.dark-mode body #AFM_inContentTop_ad iframe {
  box-shadow: none;
}
/*# sourceMappingURL=style.css.map */
